import styled, { css } from 'styled-components';
import Spoiler from '@/components/blocks/Spoiler/Spoiler';
import { offset, font, sideOffsets, color, mediaBreakpointUp, hover } from '@/style/mixins';
import { SpoilerHead, SpoilerHeadBtn, SpoilerBody } from '@/components/blocks/Spoiler/Spoiler.styled';
import { DocumentsWrapper } from '../Documents/Documents.styled';
import { Colors } from '@/style/colors';
import { BaseOffsetsUI } from '@/interfaces';
import variables, { Offsets } from '@/style/variables';

export const Container = styled.div<
    BaseOffsetsUI & {
        bgColor?: Colors;
        hasSideOffsets?: boolean;
        haveIcon?: boolean;
        borders?: boolean;
        topHeaderOffset?: Offsets;
        bottomHeaderOffset?: Offsets;
        offOffsetLeftRight?: boolean;
    }
>`
    position: relative;
    border-radius: ${variables.corners.smallUi};
    transition: background-color 0.3s ease-in-out;
    ${({ offOffsetLeftRight }) => !offOffsetLeftRight && 'margin-left: 20px; margin-right: 20px;'}
    ${({ topHeaderOffset }) => topHeaderOffset && offset(topHeaderOffset, 'margin-top')};
    ${({ bottomHeaderOffset }) => bottomHeaderOffset && offset(bottomHeaderOffset, 'margin-bottom')};

    ${({ bgColor }) =>
        bgColor &&
        css`
            ${SpoilerHead} {
                background-color: ${color(bgColor)};
                ${hover(`
                    background-color: ${bgColor === 'white' ? color('Content-01-hover') : color('Content-02-hover')};
                `)}
            }
        `};

    ${mediaBreakpointUp('xl')} {
        margin-left: 0;
        margin-right: 0;
    }

    ${({ borders }) =>
        borders &&
        css`
            border-top: 1px solid ${color('black', 0.1)};
            border-bottom: 1px solid ${color('black', 0.1)};
        `}

    ${SpoilerBody} {
        & > div {
            background-color: ${({ bgColor, haveIcon }) => haveIcon && bgColor === 'gray2' && color('white')};
            ${({ hasSideOffsets }) => hasSideOffsets && sideOffsets('margin')}
            ${({ topOffset }) => topOffset && offset(topOffset, 'margin-top')}
            ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'margin-bottom')}
        }
        ${DocumentsWrapper} {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
    }
`;

export const ContentSpoilerLeftHead = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;

    ${mediaBreakpointUp('xl')} {
        align-items: center;
        gap: 20px;
    }

    & > svg {
        fill: currentColor;
        width: 24px;
        height: 24px;

        ${mediaBreakpointUp('xl')} {
            width: 30px;
            height: 30px;
        }
    }
    h4 {
        font: ${font('t1-body-exb')};
        flex: 1;
    }
`;

export const ContentSpoilerItem = styled(Spoiler)<{ haveIcon?: boolean }>`
    ${SpoilerHead} {
        user-select: none;
        align-items: center;
        ${offset('m', 'padding-top')};
        ${offset('m', 'padding-bottom')};
        ${offset('l', 'padding-left')};
        ${offset('l', 'padding-right')};

        ${({ haveIcon }) => (haveIcon ? font('h3') : font('t1-body-exb'))};
    }

    ${SpoilerHeadBtn} {
        transition: color 0.3s ease-in-out;

        svg {
            height: 16px;
            width: 16px;

            ${mediaBreakpointUp('md')} {
                height: 20px;
                width: 20px;
            }
        }
    }

    ${SpoilerBody} {
        & > div {
            border-radius: 8px;
        }
    }

    &._is-open {
        ${SpoilerHead} {
            &:after {
                border-bottom: 1px solid ${color('black', 0.1)};
            }
        }
    }
`;

export const ContentSpoilerInnerText = styled.div``;
