import styled from 'styled-components';
import { Container as Card1 } from '@/components/blocks/Card1/Card1.styled';
import { ListWrapper as List, Item } from '@/components/common/List/List.styled';
import { color, mediaBreakpointUp, font, offset, hover, adaptiveHardValues } from '@/style/mixins';
import { Colors } from '@/style/colors';
import { BaseOffsetsUI } from '@/interfaces';

export const Container = styled.div<BaseOffsetsUI & { bgColor?: Colors; section?: Colors }>`
    position: relative;
    display: flex;
    gap: 24px;
    flex-direction: column-reverse;
    margin: 0 20px;
    ${({ topOffset }) => (topOffset ? offset(topOffset, 'padding-top') : offset('xxxl', 'padding-top'))};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'padding-bottom')};

    background-color: ${({ bgColor }) => bgColor && color(bgColor)};

    ${mediaBreakpointUp('md')} {
        gap: 32px;
    }

    ${mediaBreakpointUp('xl')} {
        display: grid;
        gap: 119px;
        grid-template-columns: 389px 1fr;
        margin: 0;
    }

    ${Card1} {
        ${adaptiveHardValues('height', 158, 143, 137)};

        ${mediaBreakpointUp('md')} {
            width: 356px;
        }

        ${mediaBreakpointUp('xl')} {
            width: 389px;
        }

        h3 {
            opacity: 1;
            visibility: visible;
        }
    }
    ${List} {
        background: none;

        ${Item} {
            position: relative;
            text-indent: 0.5em;
            ${font('t1-body-sb')};

            &:before {
                content: '—';
                position: absolute;
                top: 0;
                left: -0.5em;
                width: 0;
                height: 0;
            }
        }
    }
`;

export const ProceduresListWrapper = styled.div`
    position: relative;

    a {
        color: ${color('link-default')};

        ${mediaBreakpointUp('xl')} {
            transition: color 0.3s ease-in-out;

            ${hover(`
                color: ${color('link-hover')};
            `)};
        }
    }
`;

export const ProceduresListTitle = styled.p`
    ${font('h1')};
    ${offset('xl', 'margin-bottom')};
`;

export const ProceduresListSmallText = styled.p`
    ${font('text2-sb')};
    ${offset('m', 'margin-top')};
    ${offset('m', 'margin-bottom')};
    color: ${color('text-secondary')};
`;

export const ProceduresListText = styled.p`
    ${font('t1-body-sb')};
`;
